<template>
  <div>
    <Header :active="active" />
    <!-- banner -->
    <div class="banner_box">
      <div class="info">
        <h3 class="banner_title">团队阵容</h3>
        <p>
          我们的创始人曾任360集团技术总裁兼首席安全官<br />
          连同行业众多专家共同为中国网络安全企业服务
        </p>
      </div>
      <div class="more_btn">
        <i class="icon el-icon-arrow-down"></i>
      </div>
    </div>
    <!-- 团队 -->
    <div class="team_box">
      <div class="contenter">
        <ul class="team_list clearfix">
          <li class="team_item fl">
            <div class="img_box">
              <img src="../assets/images/team/TanXiaosheng.png" alt="" />
            </div>

            <div class="team_name">
              <span class="name">谭晓生</span><span class="title">创始人</span>
            </div>
            <div class="des_info"></div>
          </li>
          <li class="team_item fl">
            <img src="../assets/images/team/TanXiaosheng.png" alt="" />
            <div class="team_name">
              <span class="name">谭晓生</span><span class="title">创始人</span>
            </div>
            <div class="des_info"></div>
          </li>
        </ul>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
export default {
  components: { Header, Footer },
  name: "team",
  data() {
    return {
      active: "3",
    };
  },
  methods: {},
  mounted() {},
  created() {},
  updated() {
    window.scroll(0, 0);
  },
};
</script>
<style lang="less" scoped>
@import url("../assets/css/team.less");
</style>